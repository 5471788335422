import React, { Dispatch, FC, SetStateAction } from 'react';
import styled from 'styled-components';

import { Text } from 'components';
import { PlatformType } from './HowToSection';

interface PlatformSelectorProps {
  platform: PlatformType;
  onSelect: Dispatch<SetStateAction<PlatformType>>;
  iosTabTitle: string;
  androidTabTitle: string;
}

const Container = styled.div`
  width: 100%;
  max-width: 11.625rem;
  border-radius: 3.24956rem;
  display: flex;
  flex-direction: row;
  background-color: #fff;
`;

const PlatformSection = styled.button<{ isActive: boolean }>`
  display: flex;
  height: 1.8125rem;
  padding: 0.25994rem 1.125rem;
  justify-content: center;
  align-items: center;
  background-color: ${({ isActive }) => (isActive ? '#3FA134' : '#fff')};
  cursor: pointer;
  border: none;
  border-radius: 3.24956rem;
  width: 5.8125rem;
`;

const PlatformSelector: FC<PlatformSelectorProps> = ({
  platform,
  onSelect,
  iosTabTitle,
  androidTabTitle,
}) => {
  const handleIOS = () => {
    onSelect('ios');
  };

  const handleAndroid = () => {
    onSelect('android');
  };

  const isAndroidAcive = platform === 'android';

  const isIOSAcive = platform === 'ios';

  return (
    <Container>
      <PlatformSection isActive={isIOSAcive} onClick={handleIOS}>
        <StyledText color={isIOSAcive ? '#fff' : '#000'}>
          {iosTabTitle}
        </StyledText>
      </PlatformSection>
      <PlatformSection isActive={isAndroidAcive} onClick={handleAndroid}>
        <StyledText color={isAndroidAcive ? '#fff' : '#000'}>
          {androidTabTitle}
        </StyledText>
      </PlatformSection>
    </Container>
  );
};

export default PlatformSelector;

const StyledText = styled.p<{ color: string }>`
  color: ${({ color }) => color};
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.125rem;
`;
